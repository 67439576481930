<template>
  <div id="Main">
    <Title title="Cadastro de Composição" />
    <Button
      text="Adicionar uma nova composição"
      @add-fields-button="addFieldsButton()"
    />
    <div class="formBordas">
     <b-row class="aling-left">
        <b-col cols="12" sm="3" md="3" class="no-padding-bottom">
          <label>Data cadastro:</label>
          <DefaultDatePicker
            :maxDate="data_fim"
            :label="''"
            :data="data_inicio"
            @update:returnDate="data_inicio = $event"/>
        </b-col>
        <b-col cols="12" sm="3" md="3" class="no-padding-bottom">
        <label>até:</label>
        <DefaultDatePicker
          :minDate="data_inicio"
          :label="''"
          :disabled="disabledDataFim"
          :data="data_fim"
          @update:returnDate="data_fim = $event"/>
        </b-col>
        <b-col cols="12" sm="6" md="6" class="no-padding-bottom">
          <label>Usuário cadastro:</label>
          <ProductSearchUser
            :label="''"
            @update:value="usuario = $event"/>
        </b-col>
      </b-row>
      <b-row class="aling-left">
        <b-col cols="12" sm="6" md="6" style="padding: 0px 10px">
          <span>Pesquisar por:</span>
        </b-col>
        <b-col cols="12" sm="6" md="6" style="padding: 0px 10px" v-show="pesquisarPorMateriaPrima">
          <span>Controla estoque matéria-prima</span>
        </b-col>
      </b-row>
      <b-row class="aling-left" style="height:60px">
        <b-col cols="12" sm="3" md="3" style="padding: 0px 10px">
          <v-checkbox
            input-value="true"
            :disabled="pesquisarPorProdutoAcabado"
            color="gray"
            v-model="pesquisarPorProdutoAcabado"
            :label="'Produto Acabado'"
            @click="zeraMateriaPrima()"
          ></v-checkbox>
        </b-col>
        <b-col cols="12" sm="3" md="3" style="padding: 0px 10px">
          <v-checkbox
            :disabled="pesquisarPorMateriaPrima"
            color="gray"
            v-model="pesquisarPorMateriaPrima"
            :label="'Matéria-prima'"
            @click="zeraProdAcabado()"
          ></v-checkbox>
        </b-col>
        <b-col cols="12" sm="6" md="6" style="padding: 0px 10px" v-show="pesquisarPorMateriaPrima">
          <v-select
          :items="opcoesControlaEstoque"
          item-text="nome"
          item-value="code"
          v-model="controlaEstoque"
          solo
          />
        </b-col>
      </b-row>
      <ProductSearchComp
        @update:value="pesquisa = $event"/>
      <b-row class="submit">
        <v-btn @click="pesquisar">Pesquisar</v-btn>
      </b-row>
    </div>
    <div class="formBordas2">
      <template>
        <div>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="itensEncontrados"
            class="elevation-1"
            :page.sync="page"
            :items-per-page="10"
            @page-count="pageCount = $event"
            :loading="setLoading"
            loading-text="Carregando...">

            <template v-slot:no-data>
              <h4 class="mb-1 mt-1 no-data">
                {{noData}}
              </h4>
            </template>

            <template v-slot:top>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="headline">Tem certeza que deseja excluir essa composição?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                    <v-btn color="blue darken-1" text @click="confirmDeletItem">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:item.composition[0].created_at="{ item }">
              <span>
                {{formataData(item.composition[0].created_at)}}
              </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-row style="padding-left: 10px">
              <v-icon
                small
                class="mr-2"
                title="Ver Composição"
                @click="abrirComposicao(item)"
              >
                mdi-magnify
              </v-icon>
              <v-icon
                v-show="item.composition[0].production_orders_open_count == 0"
                small
                class="mr-2"
                title="Editar"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="mr-2"
                title="Histórico"
                @click="abrirHistorico(item)"
              >
                mdi-clock-outline
              </v-icon>
              <v-icon
                v-show="item.composition[0].production_orders_open_count == 0"
                small
                class="mr-2"
                title="Excluir"
                @click="deletItem(item)"
              >
                mdi-delete
              </v-icon>
              </v-row>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
                v-show="!this.setLoading && itensEncontrados.length > 0"
                v-model="page"
                :length="pageCount"
                :total-visible="7"
            ></v-pagination>
          </div>
        </div>
      </template>
    </div>
    <Modal id="addModal" :edit="edit" :item="itemCompModal" :limpaDados="limpaDados"/>
    <CompModal id="compModal" :item="itemCompModal"/>
    <HistModal id="histModal" :item="itemHistModal"/>
  </div>
</template>



<script>
import swal from 'sweetalert2';
import Title from '@/components/cadastroComposicao/Title';
import Button from '@/components/cadastroComposicao/Button';
import Table from '@/components/cadastroComposicao/Table';
import Modal from '@/components/cadastroComposicao/Modal';
import CompModal from '@/components/cadastroComposicao/CompModal';
import HistModal from '@/components/cadastroComposicao/HistModal';
import moment from "moment";
import apiInstance from "../../services/api/config";
import TableFilter from '@/components/cadastroComposicao/TableFilter';
import DefaultDatePicker from '@/components/generics/DefaultDatePicker';
import ProductSearchUser from "@/components/search-components/ProductSearchUser";
import ProductSearchComp from "@/components/search-components/ProductSearchComp";

export default {
  name: 'Main',
  components: {
    ProductSearchComp,
    ProductSearchUser,
    DefaultDatePicker,
    TableFilter,
    Button,
    Title,
    Table,
    Modal,
    CompModal,
    HistModal,
  },
  data() {
    return {
      limpaDados: 0,
      dialogDelete: false,
      data_inicio: null,
      data_fim: null,
      disabledDataFim: true,
      pesquisarPorProdutoAcabado: true,
      pesquisarPorMateriaPrima: false,
      setLoading: false,
      pageCount: 0,
      page: 1,
      totalRows: 0,
      pesquisa: {
        pve_codigo: '',
      },
      usuario: {usu_nome: ''},
      itensEncontrados: [],
      itemCompModal: {},
      itemHistModal:{
          product_variety: {
            product_mobile: {
              pro_codigo: '',
              pro_descricao_publica: '',
            }
          }
      },
      controlaEstoque: -1,
      opcoesControlaEstoque: [
        {nome:'--SELECIONE--', code:-1},
        {nome:'Sim', code:1}, 
        {nome:'Não', code:0},
      ],
      headers: [
        {text: 'Cód. prod. acabado', value: 'pve_codigo', sortable: false, width: '11%'},
        {text: 'Descrição', value: 'product_complete_name', sortable: false, width: '42%'},
        {text: 'Unidade de medida', value: 'measurement_unit.unm_descricao', sortable: false, width: '10%'},
        {text: 'Data última edição', value: 'composition[0].created_at', sortable: false, width: '10%'},
        {text: 'Usuário cadastro', value: 'composition[0].created_by', sortable: false, width: '14%'},
        {text: 'Opções', value: 'actions', sortable: false, width: '13%'}
      ],
      noData: 'Entre com as informações de pesquisa',
      pesquisaProduto: '',
      edit: '',
    };
  },
  watch:{
    data_inicio(){
      if (this.data_inicio != null){
        this.disabledDataFim = false;
      }
    }, 
  },
  methods: {
    addFieldsButton() {
      this.edit = 0;
      this.itemCompModal = {};
      this.limpaDados += 1;
      this.$bvModal.show('addModal');
    },
    editItem(item){
      this.edit += 1;
      this.itemCompModal = item;
      this.$bvModal.show('addModal');
    },
    deletItem(item){
      this.editedIndex = this.itensEncontrados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    confirmDeletItem(){
      apiInstance
          .delete('/composite-product/' + this.editedItem.pve_codigo)
          .then((response) => {
            swal
                .fire({
                  title: '',
                  text: 'Composição excluída com sucesso !',
                  icon: 'success',
                  timer: 2000,
                })
                .then(willDelete => {
                });
          })
          .catch((error) => {
            console.log(error);
          })
      this.itensEncontrados.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete(){
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem)
        this.editedIndex = -1
      })
    },
    abrirComposicao(item){
      this.itemCompModal = item;
      this.$bvModal.show('compModal');
    },
    abrirHistorico(item){
      this.itemHistModal = item;
      this.$bvModal.show('histModal');
    },
    zeraProdAcabado(){
      this.pesquisarPorProdutoAcabado = false;
      this.controlaEstoque = -1;
    },
    zeraMateriaPrima(){
      this.pesquisarPorMateriaPrima = false;
      this.controlaEstoque = -1;
    },
    setPesquisa(){
      if(this.pesquisa.pve_codigo != ''){
        if(this.pesquisarPorProdutoAcabado){
          this.pesquisaProduto = 'pcp_fk_pve_codigo_acabado=' + this.pesquisa.pve_codigo;
        }else{
          this.pesquisaProduto = 'pcp_fk_pve_codigo_materia_prima=' + this.pesquisa.pve_codigo;
        }
      }
      if(this.pesquisaProduto != '' && this.usuario.usu_nome != ''){
        this.pesquisaProduto = this.pesquisaProduto + '&';
      }
      if(this.usuario.usu_nome != ''){
        this.pesquisaProduto = this.pesquisaProduto + 'usu_nome=' + this.usuario.usu_nome;
      }
      if(this.pesquisaProduto != '' && this.controlaEstoque != -1){
        this.pesquisaProduto = this.pesquisaProduto + '&';
      }
      if(this.controlaEstoque != -1){
        this.pesquisaProduto = this.pesquisaProduto + 'control_wharehouse=' + this.controlaEstoque;
      }
      if(this.pesquisaProduto != '' && this.data_inicio != null){
        this.pesquisaProduto = this.pesquisaProduto + '&';
      }
      if(this.data_inicio != null){
        this.pesquisaProduto = this.pesquisaProduto + 'initial_date=' + this.data_inicio + ' %2000%3A00%3A00';
      }
      if(this.pesquisaProduto != '' && this.data_fim != null){
        this.pesquisaProduto = this.pesquisaProduto + '&';
      }
      if(this.data_fim != null){
        this.pesquisaProduto = this.pesquisaProduto + 'final_date=' + this.data_fim + '%2023%3A59%3A59';
      }

    },
    pesquisar(){
      this.setLoading = true;
      if(this.itensEncontrados != []){
        this.itensEncontrados = [];
      }
      this.pesquisaProduto = '';
      this.setPesquisa();
        apiInstance
        .get('/composite-product?' + this.pesquisaProduto)
        .then(res => {
          this.setLoading = false;
          this.itensEncontrados = res.data.data;
        });
      /*
      this.pesquisa = {
        pve_codigo: '',
      };
      this.usuario = {};
      this.data_inicio = null;
      this.data_fim = null;
      this.controlaEstoque = -1;
      this.limpaDados += 1;
      this.limpaDadosData += 1;
      this.pesquisaProduto = '';
      */
      this.noData = 'Nenhum item encontrado!';
    },
    formataData(data){
      return moment(String(data)).format('DD/MM/YYYY')
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.formBordas{
  border: solid 1px  rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.formBordas2{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em;
  padding: 0px;
  margin-bottom: 20px;
}
.aling-left{
  text-align: left;
}
.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}
.no-padding{
  padding: 0px 5px;
}
.no-padding-bottom{
  padding-bottom: 0px;
}
.no-data{
  font-size: 15px;
}

</style>

<style>
  .modal-footer{
    justify-content: center;
  }
</style>
